const baseurl = "https://node-ecomm.mobiloitte.com"; //UAT
const user = `${baseurl}/api/v1/user`;
const admin = `${baseurl}/api/v1/admin`;
const fees = `${baseurl}/api/v1/fees`;
const contract = `${baseurl}/api/v1/contract`;
export const socketURL = "wss://node-kyle.mobiloitte.com";
const apiConfig = {
  connectWallet: `${user}/connectWallet`,
  addPresale: `${user}/addPresale`,
  presaleList: `${user}/presaleList`,
  viewPresale: `${user}/viewPresale/`,
  viewStacking: `${user}/viewStacking/`,
  approvePresaleUser: `${admin}/approvePresale`,
  contractSummary: `${contract}/contractSummary`,
  addContract: `${contract}/addContract`,
  presaleListAdmin: `${admin}/presaleList`,
  listFees: `${fees}/listFees`,
  editFees: `${fees}/editFees`,
};

export default apiConfig;
