import { Interface } from "@ethersproject/abi";
import Web3 from "web3";
import MultiCallAbi from "../abis/Multicall.json";
import {
  mulltiCallAddressEth,
  mulltiCallAddressMatic,
  mulltiCallAddressQi,
} from "./index";
import { default_RPC_URL } from "../constants/";
// import { getWeb3Obj } from "src/utils";
import { getWeb3ContractObject, getWeb3Obj } from "./index";

const multicall = async (abi, calls) => {
  const multi = await getWeb3ContractObject(
    MultiCallAbi,
    mulltiCallAddressQi, //   "0x32F8647b2ff89B454170A25037D7643d219d6A19";
    default_RPC_URL
  );
  const itf = new Interface(abi);
  const calldata = calls.map((call) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params),
  ]);
  const { returnData } = await multi.methods.aggregate(calldata).call();
  const res = returnData.map((call, i) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );
  return res;
};

export default multicall;
